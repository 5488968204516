import Vue from 'vue'
import Vuetable from 'vuetable-2'
import VuetablePagination from "vuetable-2/src/components/VuetablePagination";
import VuetableFieldCheckbox from 'vuetable-2/src/components/VuetableFieldCheckbox.vue';
import VuetableFieldMixin from 'vuetable-2/src/components/VuetableFieldMixin'
import $ from 'jquery'
import axios from "axios";
import JsonExcel from 'vue-json-excel'
import vSelect from 'vue-select'
import moment from 'moment'
import DynamicInput from '../../components/DynamicInput.vue'

Vue.component('downloadExcel', JsonExcel)
Vue.component('v-select', vSelect)
Vue.component('dynamic-input', DynamicInput)


export default {
    name: "TresoreriesMouvement",
    components: {
        Vuetable,
        VuetablePagination,
        VuetableFieldCheckbox,
        VuetableFieldMixin
    },
    data: function() {

        return {
            pageTitle: "Historique des depenses par voiture",

            vuetable: {
                moreParams: {
                    filter: "",
                    criteriacol: "",
                    users_id: "",
                    vehicules_id: "",
                    fournisseurs_id: "",
                    statut_depense: "",
                    startDate: moment().format('DD/MM/YYYY'),
                    endDate: moment().format('DD/MM/YYYY'),
                },
                fields: [
                    // {
                    //     name: VuetableFieldCheckbox,
                    //     titleClass: 'text-center',
                    //     dataClass: 'text-center'
                    // },
                    {
                        name: 'date_depense_formatted',
                        title: 'Date',
                        sortField: 'date_depense',
                        dataClass: 'text-center',
                        titleClass: 'text-center',
                        width: "80px"
                    },
                    {
                        name: 'nom_type',
                        title: 'Type de depense',
                        sortField: 'nom_type',
                        dataClass: 'text-left',
                        titleClass: 'text-left',
                        visible: true,
                    },
                    {
                        name: 'matricule',
                        title: 'Vehicule',
                        sortField: 'matricule',
                        dataClass: 'text-left',
                    },
                    {
                        name: 'nom_fournisseur',
                        title: 'Fournisseurs',
                        sortField: 'nom_fournisseur',
                        dataClass: 'text-left',
                    },
                    {
                        name: 'libelle_depense',
                        title: 'Titre',
                        sortField: 'libelle_depense',
                        dataClass: 'text-left',
                    },
                    {
                        name: 'num_mvt',
                        title: 'Ref. Caisse',
                        sortField: 'num_mvt',
                        dataClass: 'text-center p-0',
                        titleClass: 'text-center',
                        width: "130px"
                    },
                    {
                        name: 'montant',
                        title: 'Montant',
                        sortField: 'montant',
                        dataClass: 'text-right',
                        titleClass: 'text-center',
                        width: "150px",
                    },
                    {
                        name: 'date_heure_enregistrement',
                        title: 'Date Enr.',
                        sortField: 'date_heure_enregistrement',
                        dataClass: 'text-center',
                        titleClass: 'text-center',
                        width: "150px",
                        visible: true,
                    },
                    {
                        name: 'nom',
                        title: 'Utilisateur',
                        sortField: 'nom',
                        dataClass: 'text-left',
                        titleClass: 'text-left',
                        visible: false,
                    },

                    {
                        name: 'actions',
                        width: "80px",
                        dataClass: "text-center"
                    }

                ],
                sortOrder: [
                    { field: 'id', direction: 'desc' }
                ],
                css: {
                    table: {
                        tableClass: 'table table-striped table-bordered table-hovered',
                        loadingClass: 'loading',
                        ascendingIcon: 'fas fa-arrow-up fa-sm',
                        descendingIcon: 'fas fa-arrow-down fa-sm',
                        handleIcon: 'fas fa-bars fa-sm',
                    },
                    pagination: {
                        infoClass: 'pull-left ',
                        wrapperClass: 'vuetable-pagination text-center',
                        activeClass: 'btn-secondary',
                        disabledClass: 'disabled',
                        pageClass: 'btn btn-border',
                        linkClass: 'btn btn-border',
                        icons: {
                            first: '',
                            prev: '',
                            next: '',
                            last: '',
                        },
                    }
                },
            },
            exportexcel: {
                json_fields: {
                    'Date': 'date_depense',
                    'Ref. Caisse': 'num_mvt',
                    'Titre': 'libelle',
                    'Déscription': 'description',
                    'Fournisseurs': 'nom_fournisseur',
                    'Vehicules': 'matricule',
                    'Montant': 'montant',
                    'Statut': 'statut_depense',
                    'Utilisateur': 'nom',
                    'Date Enr.': 'date_heure_enregistrement'
                },
                json_data: [],
                json_meta: [
                    [{
                        'key': 'charset',
                        'value': 'utf-8'
                    }]
                ],
            },
            motCle: "",
            crudform: {
                id: "",
                date_depense_formatted: moment().format('DD/MM/YYYY'),
                libelle_depense: "",
                description: "",
                tresoreries_id: "",
                nom_fournisseur: "",
                vehicules_id: "",
                montant: "",
                statut_depense: "",
                champs: []
            },
            crudmodaltitle: "Nouvelle dépense par voiture",
            listdata: {
                users: [],
                tresoreries: [],
                vehicules: [],
                fournisseurs: [],
                types: [],

            },

            datepicker: {
                format: 'DD/MM/YYYY',
                useCurrent: false,
            },
            filtreDate: {
                dateDebut: moment().format('DD/MM/YYYY'),
                dateFin: moment().format('DD/MM/YYYY')
            },
            produits: [],
            details: [],
            critereproduit: {
                type: "",
                categorie: "",
                search: ""
            },
            typesdepense: {},


        }
    },
    methods: {
        onPaginationData(paginationData) {
            this.$refs.pagination.setPaginationData(paginationData)
        },
        onChangePage(page) {
            this.$refs.vuetable.changePage(page)
        },
        editRow(rowData) {
            //alert("You clicked edit on" + JSON.stringify(rowData))
            axios.get(this.BASE_URL + "/depenses/get/" + rowData.id).then(response => {
                this.crudform = response.data[0];
            });
            this.crudmodaltitle = "Editer un mouvement de trésorérie";
            this.openModal();
        },
        deleteRow(rowData) {
            //alert("You clicked delete on" + JSON.stringify(rowData));
            var that = this;
            this.$bvModal.msgBoxConfirm('Voulez-vous vraiment supprimer cette ligne?', {
                    title: 'Confirmation',
                    size: 'md',
                    buttonSize: 'sm',
                    okVariant: 'danger',
                    okTitle: 'Supprimer',
                    cancelTitle: 'Annuler',
                    footerClass: 'p-2',
                    hideHeaderClose: false,
                    centered: true
                })
                .then(value => {
                    console.log(value);
                    if (value == true) {
                        axios.post(that.BASE_URL + "/depenses/delete/" + rowData.id).then(function(response) {
                            console.log(response);
                            //this.message_reponse = response.data.msg;
                            if (response.data.error === "") {
                                console.log(response.data.message);
                                that.setFilter();
                                Vue.$toast.open({
                                    message: response.data.message,
                                    type: 'success',
                                    position: 'top-right'
                                        // all of other options may go here
                                });
                            } else {
                                console.log("Erreur");
                                Vue.$toast.open({
                                    message: response.data.error,
                                    type: 'error',
                                    position: 'top-right'
                                        // all of other options may go here
                                });
                            }
                        });
                    }
                })
                .catch(err => {
                    console.log(err);
                    // An error occurred
                })

        },
        onLoading() {
            console.log('loading... show your spinner here')
        },
        onLoaded() {
            console.log('loaded! .. hide your spinner here');
            // this.$refs.vuetable.$data.tableData
        },
        setFilter() {
            this.fetchData();
            this.vuetable.moreParams.filter = this.motCle;
            this.vuetable.moreParams.criteriacol = this.criteriacol;
            this.vuetable.moreParams.startDate = this.filtreDate.dateDebut;
            this.vuetable.moreParams.endDate = this.filtreDate.dateFin;


            Vue.nextTick(() => this.$refs.vuetable.refresh());
        },
        resetFilter() {
            this.fetchData();
            this.motCle = "";
            this.criteriacol = "";
            this.vuetable.moreParams.users_id = "";
            this.vuetable.moreParams.fournisseurs_id = "";
            this.vuetable.moreParams.vehicules_id = "";
            this.vuetable.moreParams.statut_depense = "";
            Vue.nextTick(() => this.$refs.vuetable.refresh());
        },
        onExport() {
            this.exportexcel.json_data = this.$refs.vuetable.$data.tableData;
            $("#excel-download").trigger("click");
        },
        openModal() {
            this.$bvModal.show("crudmodal");
        },
        closeModal() {
            this.$bvModal.hide("crudmodal");
        },
        openCustomModal() {
            this.$bvModal.show("myCustomModal");
        },
        closeCustomModal() {
            this.$bvModal.hide("myCustomModal");
        },
        openFilterModal() {
            this.$bvModal.show("filterModal");
        },
        closeFilterModal() {
            this.$bvModal.hide("filterModal");
        },
        addRow() {
            this.crudmodaltitle = "Nouveau mouvement de Trésorésie";
            this.crudform = {
                id: "",
                types_depense_id: "",
                date_depense_formatted: moment().format('DD/MM/YYYY'),
                libelle_depense: "",
                description: "",
                tresoreries_id: "",
                nom_fournisseur: "",
                vehicules_id: "",
                montant: "",
                statut_depense: "",
                champs: [],
            };
            this.typesdepense = {};

            this.openModal();
        },
        onSubmit() {
            var that = this;
            this.$bvModal.msgBoxConfirm('Voulez-vous vraiment continuer l\'enregistrement?', {
                    title: 'Confirmation',
                    size: 'md',
                    buttonSize: 'sm',
                    okVariant: 'success',
                    okTitle: 'Continuer',
                    cancelTitle: 'Annuler',
                    footerClass: 'p-2',
                    hideHeaderClose: false,
                    centered: true
                })
                .then(value => {
                    console.log(value);
                    if (value == true) {
                        var link = that.crudform.id == '' ? that.BASE_URL + "/depenses/addaction" : that.BASE_URL + "/depenses/editaction";
                        axios.post(link, $("#formulaire").serialize()).then(function(response) {
                            console.log(response);
                            //this.message_reponse = response.data.msg;
                            if (response.data.error === "") {
                                console.log("Enregistrement effectué avec succès!");
                                that.closeModal();
                                that.setFilter();
                                Vue.$toast.open({
                                    message: 'Enregistrement effectué avec succès!',
                                    type: 'success',
                                    position: 'top-right'
                                        // all of other options may go here
                                });
                            } else {
                                console.log("Erreur");
                                Vue.$toast.open({
                                    message: response.data.error,
                                    type: 'error',
                                    position: 'top-right'
                                        // all of other options may go here
                                });
                            }
                        });
                    }
                })
                .catch(err => {
                    console.log(err);
                    // An error occurred
                });
        },
        fetchData: function() {
            axios.get(this.BASE_URL + "/depenses/fetchdata").then(response => {
                this.listdata = response.data;
            });

        },

        delFilter(model) {
            this.vuetable.moreParams[model] = "";
            this.setFilter();
        },
        initDate() {
            var currentDateWithFormat = moment().format('DD/MM/YYYY');
            if (localStorage.dateDepense) {
                var dateDepense = JSON.parse(localStorage.dateDepense);
                // Si les deux dates etait égaux on met la date d'aujourdui
                if (dateDepense.dateDebut == dateDepense.dateFin) {
                    dateDepense = {
                        dateDebut: currentDateWithFormat,
                        dateFin: currentDateWithFormat
                    };
                    localStorage.dateDepense = JSON.stringify(dateDepense);
                }
                this.filtreDate = {
                    dateDebut: dateDepense.dateDebut,
                    dateFin: dateDepense.dateFin
                };
            } else {
                dateDepense = {
                    dateDebut: currentDateWithFormat,
                    dateFin: currentDateWithFormat
                };
                localStorage.dateDepense = JSON.stringify(dateDepense);
                this.filtreDate = {
                    dateDebut: dateDepense.dateDebut,
                    dateFin: dateDepense.dateFin
                };
            }
        },
        setDate() {
            localStorage.dateDepense = JSON.stringify(this.filtreDate);
        },
        getLibelle() {
            axios.get(this.BASE_URL + "/depenses/getlibelle/" + this.crudform.type_mvt).then(response => {
                this.listdata.libelles = response.data.map(o => o.libelle_mvt);
                console.log(this.listdata.libelles);
            });
        },
        getTypesDepense: function() {
            axios.get(this.BASE_URL + "/typesdepense/get/" + this.crudform.types_depense_id).then(response => {
                this.typesdepense = response.data[0];
            });
        }

    },
    computed: {
        filterId() {
            return (list, libelle, id) => {
                if (!list || list.length == 0 || !(id > 0))
                    return "";
                return list.filter(item => item.id == id)[0][libelle];
            };
        },
        tresoreriesdest() {
            return this.listdata.tresoreries.filter(t => t.id != this.crudform.tresoreries_id);
        },
        total_data() {
            var data = this.listdata.tresoreries.filter(o => this.vuetable.moreParams.tresoreries_id == '' || o.id == this.vuetable.moreParams.tresoreries_id);
            data.forEach(o => {
                o.total_credit = 0;
                o.total_debit = 0;
            });
            if (this.$refs.vuetable && this.$refs.vuetable.$data.tablePagination) {
                var total = this.$refs.vuetable.$data.tablePagination.total;
                data.forEach(o => {
                    o.total_credit = total.total_credit.filter(r => r.tresoreries_id == o.id).length > 0 ? total.total_credit.filter(r => r.tresoreries_id == o.id)[0].total_credit : 0;
                    o.total_debit = total.total_debit.filter(r => r.tresoreries_id == o.id).length > 0 ? total.total_debit.filter(r => r.tresoreries_id == o.id)[0].total_debit : 0;
                });
            }
            console.log(data);
            return data;

        },
        produitList: function() {
            var that = this;
            return this.produits.filter(function(produit) {
                if (produit.reference.toLowerCase().includes(that.critereproduit.search.toLowerCase()) || produit.designation.toLowerCase().includes(that.critereproduit.search.toLowerCase())) {
                    return true;
                }
                return false;
            });
        },


    },
    created: function() {
        this.$emit('change-page', this.pageTitle);
        console.log(this.$route);
        if (this.$route.name == 'TresoreriesMouvementparid' && this.$route.params.tresoreries_id) {
            this.vuetable.moreParams.tresoreries_id = this.$route.params.tresoreries_id;
        }

        this.initDate();

        this.motCle = "";
        this.criteriacol = "";
        this.fetchData();
        // this.setFilter();
    },
    mounted: function() {
        if (this.$route.params.type == 'add') {
            this.addRow();
        }

    },
    watch: {
        'crudform.type_mvt': function() {
            this.getLibelle();
        }
    }
}